<div class="multi-select-actions" fxLayout="row" fxLayoutAlign="end start">
    <button
        class="mr-4"
        mat-raised-button
        color="accent"
        [disabled]="disabled || !hasSelectedContacts"
        (click)="
            evaluateSelectedCandidates(
                candidateEvaluationEnum.PASS,
                recruitmentTypeEnum.IMMEDIATE
            )
        "
    >
        <span class="text-uppercase">{{ 'CAMPAIGN_RESULT.OFFICIAL' | translate }}</span>
    </button>
    <button
        class="ml-4 mr-4"
        mat-raised-button
        color="accent"
        [disabled]="disabled || !hasSelectedContacts"
        (click)="
            evaluateSelectedCandidates(
                candidateEvaluationEnum.PASS,
                recruitmentTypeEnum.ADDITIONAL
            )
        "
    >
        <span class="text-uppercase">{{ 'CAMPAIGN_RESULT.BACKUP' | translate }}</span>
    </button>
    <button
        class="ml-4"
        mat-raised-button
        color="warn"
        [disabled]="disabled || !hasSelectedContacts"
        (click)="
            evaluateSelectedCandidates(
                candidateEvaluationEnum.FAIL,
                recruitmentTypeEnum.FAIL
            )
        "
    >
        <span class="text-uppercase">{{ 'CAMPAIGN_RESULT.FAIL' | translate }}</span>
    </button>
</div>
