import * as moment from 'moment';
import { Moment } from 'moment';
import { isEmpty } from 'lodash';
import { ConfirmationStatus } from '../constants/confirmationStatus';
import CampaignUtils from '../../main/campaign/shared/campaign-utils';

export class Campaign {
    id: number;
    name: string;
    description: string;
    from: Moment;
    to: Moment;
    approverId: number;
    reviewerId: number;
    ownerId: number;
    jobInfo: JobInfo;
    profileFilter: ProfileFilter;
    rounds: Round[];
    status: StatusEnum;
    feedback: Feedback;
    resultFeedback: string;
    qr: string;
    approverName?: string;
    reviewerName?: string;
    userAction?: UserAction;

    constructor(campaign?) {
        if (campaign) {
            this.id = campaign.id;
            this.name = campaign.name;
            this.description = campaign.description;
            this.from = moment(campaign.from);
            this.to = moment(campaign.to);
            this.approverId = campaign.approverId;
            this.reviewerId = campaign.reviewerId;
            this.ownerId = campaign.ownerId;
            this.jobInfo = new JobInfo(campaign.jobInfo);
            this.profileFilter = new ProfileFilter(campaign.profileFilter);
            this.rounds = campaign.rounds.map((round) => new Round(round));
            this.status = StatusEnum[campaign.status];
            this.qr = campaign.qr;
            this.resultFeedback = campaign.resultFeedback;
            this.reviewerName = campaign.reviewerName;
            this.approverName = campaign.approverName;
            this.userAction = new UserAction(campaign.userAction);
        } else {
            this.id = 0;
            this.name = '';
            this.description = '';
            this.from = moment();
            this.to = moment();
            this.approverId = 0;
            this.reviewerId = 0;
            this.ownerId = 0;
            this.jobInfo = new JobInfo();
            this.profileFilter = new ProfileFilter();
            this.rounds = [];
            this.status = StatusEnum.NOT_READY;
            this.reviewerName = '';
            this.approverName = '';
            this.userAction = new UserAction('');
        }
    }
}

export class UserAction {
    doneStep1?: boolean;
    doneStep2?: boolean;
    doneStep3?: boolean;
    currentStep?: number;
    resultSortStatus?: string;
    resultSortFields?: string[];

    constructor(userAction) {
        const setEmptyStatus = () => {
            this.doneStep1 = false;
            this.doneStep2 = false;
            this.doneStep3 = false;
            this.currentStep = 0;
            this.resultSortStatus = '';
            this.resultSortFields = [];
        };

        if (isEmpty(userAction)) {
            setEmptyStatus();
        } else {
            let parsedUserAction = userAction;
            if (typeof userAction === 'string') {
                parsedUserAction = JSON.parse(userAction);
            }
            try {
                this.doneStep1 = parsedUserAction.doneStep1;
                this.doneStep2 = parsedUserAction.doneStep2;
                this.doneStep3 = parsedUserAction.doneStep3;
                this.currentStep = parsedUserAction.currentStep;
                this.resultSortStatus = parsedUserAction.resultSortStatus;
                this.resultSortFields = parsedUserAction.resultSortFields;
            } catch (e) {
                console.log('cannot parse user Action');
                setEmptyStatus();
            }
        }
    }
}

export class Feedback {
    generalInformationFeedback: string;
    profileFilterFeedback: string;
    roundFeedback: string;

    constructor(feedback?) {
        if (feedback) {
            this.generalInformationFeedback =
                feedback.generalInformationFeedback;
            this.profileFilterFeedback = feedback.profileFilterFeedback;
            this.roundFeedback = feedback.roundFeedback;
        } else {
            this.generalInformationFeedback = '';
            this.profileFilterFeedback = '';
            this.roundFeedback = '';
        }
    }
}

export class Round {
    id: number;
    type: TestTypeEnum;
    evaluationType: ResultEvaluationTypeEnum;
    activeFrom: Moment;
    activeTo: Moment;
    testId: number;
    candidateGuide: CandidateGuide;
    sieve: number;
    organizers: number[];
    active: boolean;

    constructor(round?) {
        this.copyDataFromRound(round);
    }

    copyDataFromRound(round: Round){
        if (round) {
            this.id = round.id;
            this.type = TestTypeEnum[round.type];
            this.evaluationType =
                ResultEvaluationTypeEnum[round.evaluationType];
            this.activeFrom = moment(round.activeFrom);
            this.activeTo = moment(round.activeTo);
            this.testId = round.testId;
            this.candidateGuide = new CandidateGuide(round.candidateGuide);
            this.sieve = round.sieve || 0;
            this.organizers = round.organizers;
            this.active = round.active;
        }
    }
}

export class CandidateGuide {
    startTime: Moment;
    contactPhone: string;
    contactName: string;
    place: any;
    note: string;
    mapLink: string;

    constructor(candidateGuide?) {
        if (candidateGuide) {
            this.startTime = moment(candidateGuide.startTime);
            this.contactPhone = candidateGuide.contactPhone;
            this.contactName = candidateGuide.contactName;
            let parsedPlace = { id: 0, address: '' };
            this.place = parsedPlace;
            try {
                parsedPlace = JSON.parse(candidateGuide.place);
                this.place = {
                    id: parsedPlace.id,
                    address: parsedPlace.address,
                };
            } catch (error) {
                console.log('cannot parse place');
            }
            this.note = candidateGuide.note;
        }
    }
}

export class ProfileFilter {
    submissionDeadline: Moment;
    filterType: FilterTypeEnum;
    provinceId: number;
    districtIds: number[];
    education: EducationEnum[];
    fromAge: number;
    toAge: number;

    constructor(profileFilter?) {
        if (profileFilter) {
            this.submissionDeadline = moment(profileFilter.submissionDeadline);
            this.filterType = FilterTypeEnum[profileFilter.filterType];
            this.provinceId = profileFilter.provinceId;
            this.districtIds = profileFilter.districtIds;
            // this.education = EducationEnum[profileFilter.education];
            this.education = profileFilter.education;
            this.fromAge = profileFilter.fromAge;
            this.toAge = profileFilter.toAge;
        } else {
            this.submissionDeadline = null;
            this.filterType = FilterTypeEnum.NOT_APPLICABLE;
            this.provinceId = 0;
            this.districtIds = [];
            // this.education = EducationEnum.UNKNOWN;
            this.education = [];
            this.fromAge = 1;
            this.toAge = 100;
        }
    }
}

export class JobInfo {
    title: string;
    type: JobTypeEnum;
    number: number;
    acceptedNumber: number;
    maxAcceptedNumber: number;
    acceptingDeadline: Moment;
    description: string;
    requirement: string;
    incomeFrom: number;
    incomeTo: number;
    salaryFrom: number;
    salaryTo: number;
    address: number;
    goToWorkNote: string;
    customWorkingLocation: string;

    constructor(jobInfo?) {
        if (jobInfo) {
            this.title = jobInfo.title;
            this.type = JobTypeEnum[jobInfo.type];
            this.number = jobInfo.number;
            this.acceptedNumber = jobInfo.acceptedNumber;
            this.maxAcceptedNumber = jobInfo.maxAcceptedNumber;
            this.acceptingDeadline = moment(jobInfo.acceptingDeadline);
            this.description = jobInfo.description;
            this.requirement = jobInfo.requirement;
            this.incomeFrom = jobInfo.incomeFrom;
            this.incomeTo = jobInfo.incomeTo;
            this.salaryFrom = jobInfo.salaryFrom;
            this.salaryTo = jobInfo.salaryTo;
            this.address = jobInfo.address;
            this.customWorkingLocation = jobInfo.customWorkingLocation;
            this.goToWorkNote = jobInfo.goToWorkNote;
        } else {
            this.title = '';
            this.type = JobTypeEnum.FULL_TIME;
            this.number = 0;
            this.acceptedNumber = 0;
            this.maxAcceptedNumber = 0;
            this.acceptingDeadline = null;
            this.description = '';
            this.requirement = '';
            this.incomeFrom = 0;
            this.incomeTo = 0;
            this.salaryFrom = 0;
            this.salaryTo = 0;
            this.address = 0;
            this.customWorkingLocation = '';
            this.goToWorkNote = '';
        }
    }
}

export class Candidate {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    gender: GenderEnum;
    avatar: string;
    email: string;
    phone: string;
    address: string;
    currentAddress: string;
    birthday: Moment;
    healthCertificate: any;
    certificates: [any];
    experiences: [any];
    age: number;
    totalExperienceMonth: number;
    profile: any;

    constructor(candidate?) {
        if (candidate) {
            this.id = candidate.id;
            this.firstName = candidate.firstName;
            this.lastName = candidate.lastName;
            this.avatar = candidate.avatar;
            this.email = candidate.email;
            this.phone = candidate.phone;
            this.address = candidate.address
                ? CampaignUtils.readAddress(candidate.address)
                : candidate.address;
            this.currentAddress = candidate.currentAddress
                ? CampaignUtils.readAddress(candidate.currentAddress)
                : candidate.currentAddress;
            this.birthday = candidate.birthday;
            this.gender = candidate.gender;
            this.fullName = `${candidate.lastName} ${candidate.firstName}`;
            this.healthCertificate = candidate.healthCertificate;
            this.certificates = candidate.certificates;
            this.experiences = candidate.experiences;
            this.age =
                new Date().getFullYear() -
                new Date(candidate.birthday).getFullYear();

            this.totalExperienceMonth = 0;
            if (!isEmpty(this.experiences)) {
                this.experiences.forEach(({ year = 0, month = 0 }) => {
                    this.totalExperienceMonth += year * 12 + month;
                });
            }
            this.profile = candidate.profile;
        } else {
            this.totalExperienceMonth = 0;
        }
    }
}

export class CandidateRoundResult {
    candidate: Candidate;
    confirm: boolean;
    authorized: boolean;
    prerequisiteResult: string;
    normalResult: string;
    result: CandidateEvaluationEnum;

    constructor(candidateRoundResult?) {
        if (candidateRoundResult) {
            this.candidate = new Candidate(candidateRoundResult);

            if (
                candidateRoundResult.prerequisite &&
                candidateRoundResult.prerequisite.total
            ) {
                this.prerequisiteResult =
                    candidateRoundResult.prerequisite.score ===
                    candidateRoundResult.prerequisite.total
                        ? 'Đạt'
                        : 'Không đạt';
            } else {
                this.prerequisiteResult = 'Không đánh giá';
            }

            this.normalResult = candidateRoundResult.score
                ? candidateRoundResult.score
                : '0';
            this.confirm = candidateRoundResult.confirm;
            this.authorized = candidateRoundResult.authorized;
            this.result = candidateRoundResult.result;
        }
    }
}

export class CandidateFinalResult {
    candidate: Candidate;
    confirm: boolean;
    roundResults: RoundResult[];
    average: number;
    result: CandidateEvaluationEnum;
    recruitmentType: RecruitmentTypeEnum;
    confirmationStatus: ConfirmationStatus;
    order: number;
    id: number;
    originalProfile: any;
    evaluator: any;

    constructor(candidateFinalResult?) {
        if (candidateFinalResult) {
            this.id = candidateFinalResult.id;
            this.candidate = new Candidate(candidateFinalResult.candidate);
            this.roundResults = candidateFinalResult.rounds.map(
                (x) => new RoundResult(x)
            );
            this.confirm = candidateFinalResult.confirm;
            this.average =
                candidateFinalResult.average == null ||
                typeof candidateFinalResult.average != 'number'
                    ? 0
                    : Math.floor(candidateFinalResult.average * 10) / 10;
            this.result = candidateFinalResult.result;
            this.recruitmentType = candidateFinalResult.recruitmentType;
            this.result =
                candidateFinalResult.recruitmentType ===
                RecruitmentTypeEnum.IMMEDIATE
                    ? CandidateEvaluationEnum.PASS
                    : CandidateEvaluationEnum.NOT_AVAILABLE;
            this.order = candidateFinalResult.order;

            if (isEmpty(candidateFinalResult.rounds)) {
                this.roundResults = [
                    new RoundResult({
                        round: { type: TestTypeEnum.TEST },
                        // score: Math.floor(Math.random() * 100),
                        score: 0,
                    }),
                ];
            }
            this.confirmationStatus = candidateFinalResult.confirmationStatus;

            if (
                !isEmpty(candidateFinalResult.candidate) &&
                !isEmpty(candidateFinalResult.candidate.profile)
            ) {
                const {
                    certificate,
                    healthCertificate,
                    currentAddress,
                    experiences,
                } = candidateFinalResult.candidate.profile;

                this.candidate = new Candidate({
                    ...candidateFinalResult.candidate,
                    certificates: certificate,
                    healthCertificate,
                    currentAddress,
                    experiences,
                });
            }

            this.originalProfile = candidateFinalResult.originalProfile;
            this.evaluator = candidateFinalResult.evaluator
        }
    }
}

export class RoundResult {
    type: TestTypeEnum;
    score: number;

    constructor(roundResult?) {
        if (roundResult) {
            this.type = roundResult.round.type;
            this.score = roundResult.score;
        } else {
            this.type = null;
            this.score = 0;
        }
    }
}

export class GoToWorkNote {
    content: string;
    deadline: Moment;
}

export class CampaignSideBarMenuItem {
    title: string;
    step: number;
    last: boolean;
    hasSubmenu = false;
    subMenu?: CampaignSideBarMenuItem[];
    data?: any;
}

export enum RecruitmentTypeEnum {
    NONE = 'NONE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    IMMEDIATE = 'OFFICIAL',
    ADDITIONAL = 'BACKUP',
    LATER = 'RECRUIT_LATER',
    FAIL = 'FAIL',
}

export const RecruimentTypeEnum2LabelMapping = {
    [RecruitmentTypeEnum.NOT_AVAILABLE]: '',
    [RecruitmentTypeEnum.IMMEDIATE]: 'Tuyển chính thức',
    [RecruitmentTypeEnum.ADDITIONAL]: 'Tuyển dự bị',
    [RecruitmentTypeEnum.LATER]: 'Tuyển dụng sau',
    [RecruitmentTypeEnum.FAIL]: 'Không tuyển dụng',
};

export enum CandidateEvaluationEnum {
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    FAIL = 'FAIL',
    PASS = 'PASS',
    STANDING = 'STANDING',
}

export const CandidateEvaluationEnum2LabelMapping = {
    [CandidateEvaluationEnum.NOT_AVAILABLE]: '',
    [CandidateEvaluationEnum.FAIL]: 'Không đạt',
    [CandidateEvaluationEnum.PASS]: 'Đạt',
    [CandidateEvaluationEnum.STANDING]: 'Dự bị',
};

export enum TestTypeEnum {
    TEST = 'TEST',
    INTERVIEW = 'INTERVIEW',
}

export const TestTypeEnum2LabelMapping = {
    [TestTypeEnum.TEST]: 'Kiểm tra',
    [TestTypeEnum.INTERVIEW]: 'Phỏng vấn',
};

export enum ResultEvaluationTypeEnum {
    AUTO = 'AUTO',
    MANUAL = 'MANUAL',
}

export const ResultEvaluationTypeEnum2LabelMapping = {
    [ResultEvaluationTypeEnum.AUTO]: 'CAMPAIGN.ROUND.EVALUATION_AUTO',
    [ResultEvaluationTypeEnum.MANUAL]: 'CAMPAIGN.ROUND.EVALUATION_MANUAL',
};

export enum EducationEnum {
    SECONDARY_SCHOOL = 'SECONDARY_SCHOOL',
    HIGH_SCHOOL = 'HIGH_SCHOOL',
    INTERMEDIATE = 'INTERMEDIATE',
    UNKNOWN = 'UNKNOWN',
    COLLEGE = 'COLLEGE',
    UNIVERSITY = 'UNIVERSITY',
}

export const EducationEnum2LabelMapping = {
    [EducationEnum.UNIVERSITY]: 'Đại học',
    [EducationEnum.COLLEGE]: 'Cao đẳng',
    [EducationEnum.SECONDARY_SCHOOL]: 'Cấp 2',
    [EducationEnum.HIGH_SCHOOL]: 'Cấp 3',
    [EducationEnum.INTERMEDIATE]: 'Trung cấp',
    [EducationEnum.UNKNOWN]: 'Chứng chỉ',
};

export enum FilterTypeEnum {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    PERMANENT_ADDRESS = 'PERMANENT_ADDRESS',
    TEMPORARY_ADDRESS = 'TEMPORARY_ADDRESS',
}

export const FilterTypeEnum2LabelMapping = {
    [FilterTypeEnum.NOT_APPLICABLE]: 'CAMPAIGN.ADDRESS_FILTER.NOT_APPLICABLE',
    [FilterTypeEnum.PERMANENT_ADDRESS]: 'CAMPAIGN.ADDRESS_FILTER.PERMANENT_ADDRESS',
    [FilterTypeEnum.TEMPORARY_ADDRESS]: 'CAMPAIGN.ADDRESS_FILTER.TEMPORARY_ADDRESS',
};

export enum StatusEnum {
    NOT_READY = 'NOT_READY',
    PENDING_REVIEW = 'PENDING_REVIEW',
    REVIEWED = 'REVIEWED',
    APPROVED = 'APPROVED',
    CANCELED = 'CANCELED',
    NOTIFIED = 'NOTIFIED',
    REJECTED = 'REJECTED',
}

export enum JobTypeEnum {
    FULL_TIME = 'FULL_TIME',
    SHIFT_TIME = 'SHIFT_TIME',
    PART_TIME = 'PART_TIME',
}

export const JobTypeEnum2LabelMapping = {
    [JobTypeEnum.FULL_TIME]: 'CAMPAIGN.SHIFT.FULL_TIME',
    [JobTypeEnum.SHIFT_TIME]: 'CAMPAIGN.SHIFT.SHIFT_TIME',
    [JobTypeEnum.PART_TIME]: 'CAMPAIGN.SHIFT.PART_TIME',
};

export enum GenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
    UNKNOWN = 'UNKNOWN',
}

export const GenderEnum2LabelMapping = {
    [GenderEnum.MALE]: 'Nam',
    [GenderEnum.FEMALE]: 'Nữ',
    [GenderEnum.OTHER]: 'Khác',
    [GenderEnum.UNKNOWN]: 'Không xác định',
};

export const GenderEnum2LabelMapping2 = {
    [GenderEnum.MALE]: 'Anh',
    [GenderEnum.FEMALE]: 'Chị',
    [GenderEnum.OTHER]: 'Anh/Chị',
    [GenderEnum.UNKNOWN]: '',
};

export enum CampaignState {
    NOT_READY = 'NOT_READY',
    EDITING = 'EDITING',
    REVIEWING = 'REVIEWING',
    VIEWING = 'VIEWING',
    RUNNING = 'RUNNING',
    REJECTED = 'REJECTED',
    FILTERING = 'FILTERING',
    TESTING = 'TESTING',
    INTERVIEWING = 'INTERVIEWING',
    EVALUATING = 'EVALUATING',
    RESULT_REVIEWING = 'RESULT_REVIEWING',
    RESULT_REJECTED = 'RESULT_REJECTED',
    NOTIFYING = 'NOTIFYING',
    NOTIFIED = 'NOTIFIED',
    ACCEPTING_JOB = 'ACCEPTING_JOB',
    FINISHED = 'FINISHED',
    CLOSED = 'CLOSED',
    EXPIRED = 'EXPIRED',
    DELETED = 'DELETED',
    CANCELED = 'CANCELED',
}
